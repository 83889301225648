import Swal from "sweetalert2";
import errorIcon from "../assets/alert-icons/error.svg";
import successIcon from "../assets/alert-icons/success.svg";
import { black_500, dark_violet } from "../components/styled/constant";

type errorProps = {
  message?: string;
  title?: string;
  icon?: "error" | "success";
  confirmButtonText?: string;
  cancelButtonText?: string;
};

export function showPopup({
  message,
  title,
  icon,
  confirmButtonText,
  cancelButtonText,
}: errorProps) {
  let iconSelected =
    icon === "error" ? errorIcon : icon === "success" ? successIcon : "";

  return Swal.fire({
    title: title,
    text: message,
    imageUrl: iconSelected,
    imageWidth: 144,
    customClass: {
      image: "no-border",
      confirmButton: !!cancelButtonText ? "button-white" : "asd",
    },
    showCancelButton: !!cancelButtonText,
    confirmButtonColor: !!cancelButtonText ? "#937CF4" : "#937CF4",
    confirmButtonText: confirmButtonText || "OK",
    color: black_500,
    showCloseButton: !!cancelButtonText,

    cancelButtonText: cancelButtonText || "Cancel",
    cancelButtonColor: dark_violet,
  });
}
