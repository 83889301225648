import { useContext, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { userContext } from "../../../../context/UserContext/userContext";
import { TProductCard } from "../../../../interfaces/productAndServices";
import { violet_1 } from "../../../../components/styled/constant";
import {
  DropDownContainer,
  DropDownHeader,
  StyledInput,
  StyledLabel,
} from "../../../../components/styled/styled";
import { GrMoney } from "react-icons/gr";
import { BsTrash } from "react-icons/bs";
import useMixpanel from "hooks/useMixpanel/useMixpanel";
import { formatToSoles } from "utils/FormatsHandler";

type Props = {
  index: string;
  handlerClick: (index: string, product: TProductCard, type: "p" | "s") => void;
  handlerChangeProducts: (
    index: number,
    amount: number,
    employeerId: number,
    product?: TProductCard,
  ) => void;
  product: any;
  handlerDeleteProduct: (index: string) => void;
  bgColor?: string;
};

export default function CardProducts(props: Props): JSX.Element {
  const { track } = useMixpanel();
  const [fadeOut, setFadeOut] = useState(false);
  const {
    index,
    handlerClick,
    handlerChangeProducts,
    product,
    handlerDeleteProduct,
    bgColor,
  } = props;
  const [amount, setAmount] = useState(product?.quantity || 1);

  useEffect(() => {
    setAmount(product?.quantity || 1);
  }, [product?.quantity]);

  const { userState } = useContext(userContext);
  const { user } = userState;
  const employee = JSON.parse(localStorage.getItem("employee") || "{}");

  type ThisProduct = {
    service: TProductCard;
    quantity: number;
    employeeId: number;
    nombreEstado: string | undefined;
    type: "p" | "s";
  };
  const thisProduct: ThisProduct = product;

  const handlerChangeAmount = (
    newAmount: number,
    product?: TProductCard,
  ): void => {
    const employee = JSON.parse(localStorage.getItem("employee") || "{}");
    if (newAmount !== 0 && employee) {
      setAmount(newAmount);
      handlerChangeProducts(
        Number(index),
        newAmount,
        employee?.employeeId,
        product,
      );
    }
  };

  const handlerDelete = (index: string): void => {
    track("EliminarP");
    setFadeOut(true);
    setTimeout(() => {
      handlerDeleteProduct(index);
    }, 500);
  };

  const onClickCard = () => {
    if (thisProduct.employeeId === user?.empleadoId)
      handlerClick(index, thisProduct.service, "p");
  };

  return (
    <StyledFormGroup fadeOut={fadeOut}>
      <StyledCounter>
        <div
          onClick={() => {
            const newAmount = amount - 1 ? amount - 1 : 1;
            handlerChangeAmount(newAmount);
          }}
        >
          -
        </div>
        {amount} und{" "}
        <div
          onClick={() => {
            const newAmount = amount + 1;
            handlerChangeAmount(newAmount);
          }}
        >
          +
        </div>
      </StyledCounter>
      <StyledContainer style={{ alignItems: "flex-start" }} bgColor={bgColor}>
        <StyledLabel onClick={onClickCard}>Producto</StyledLabel>
        <div style={{ width: "100%" }} onClick={onClickCard}>
          <DropDownContainer style={{ width: "100%" }}>
            <DropDownHeader
              style={{
                margin: "0.5rem 0",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {thisProduct?.service?.descripcion || "Seleccione un producto"}
            </DropDownHeader>
          </DropDownContainer>
          <StyledLabel style={{ marginBottom: "0.5rem" }}>
            Estilista
          </StyledLabel>
          <StyledInput
            disabled
            style={{ width: "-webkit-fill-available" }}
            value={
              employee?.employeeName || user?.nombre + " " + user?.apellido
            }
          ></StyledInput>
        </div>
        <PriceContainer>
          <GrMoney height={"2rem"} />
          {thisProduct?.service !== undefined ? (
            <label>
              {amount === 1 ? (
                formatToSoles(thisProduct?.service?.precio || 0)
              ) : (
                <span>
                  <span style={{ opacity: 0.6, fontSize: "0.9em" }}>
                    {formatToSoles(thisProduct?.service?.precio || 0)} x{" "}
                    {amount} =
                  </span>{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {formatToSoles(
                      (thisProduct?.service?.precio || 0) * amount,
                    )}
                  </span>
                </span>
              )}
            </label>
          ) : (
            <label>{formatToSoles(0)}</label>
          )}

          <p
            style={{
              display: "inherit",
              textDecorationLine: "underline",
              textUnderlineOffset: "2px",
              cursor: "pointer",
              alignItems: "center",
              fontWeight: 500,
            }}
            onClick={() => handlerDelete(index)}
          >
            Eliminar <BsTrash />
          </p>
        </PriceContainer>
      </StyledContainer>
    </StyledFormGroup>
  );
}

const fadeInLeft = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
`;

const fadeOutRight = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`;
const StyledFormGroup = styled("div").attrs((props: { fadeOut: boolean }) => ({
  fadeOut: props.fadeOut,
}))`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: -webkit-fill-available;
  position: relative;
  transform: translateX(-100vw);
  animation: ${(props: { fadeOut: boolean }) => {
      return props.fadeOut ? fadeOutRight : fadeInLeft;
    }}
    0.5s ease-in-out;
  animation-fill-mode: forwards;
  z-index: 250;
`;

const StyledContainer = styled.div.attrs((props: { bgColor?: string }) => ({
  bgColor: props.bgColor,
}))`
  display: flex;
  background-color: ${(props) => props.bgColor || "none"};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5%;
  border-radius: 0.5rem;
  border: 1px solid ${violet_1};
  width: -webkit-fill-available;
`;

const PriceContainer = styled.div`
  color: ${violet_1};
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 0.5rem;
  width: -webkit-fill-available;
  font-weight: bold;
  margin-top: 2rem;
  & label {
    color: ${violet_1};
    margin-left: 1rem;
  }
  & svg {
  }
  & > svg {
    & path {
      stroke: ${violet_1};
    }
  }
`;

const StyledCounter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  div {
    align-items: center;
    text-align: center;
    border-radius: 21%;
    color: ${violet_1};
    border: 2px solid ${violet_1};
    font-weight: 900;
    padding: 0rem 0.3rem;
    margin: 0rem 0.3rem;
    cursor: pointer;
  }
`;
