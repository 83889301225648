import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { IRequest } from "../interfaces/request";
import { IUser } from "../interfaces/user";
import { loginFinalReq, preLoginReq, recoverPassReq } from "../request/login";
import { isExpired, decodeToken } from "react-jwt";
import { userContext } from "../context/UserContext/userContext";
import { showPopup } from "../utils/showAlert";
import { showLoader } from "../utils/loader";

import useMixpanel from "hooks/useMixpanel/useMixpanel";

export const useLogin = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const context = useContext(userContext);
  const { userState, setUser, setToken } = context;
  const { user } = userState;
  const location = useLocation();
  const { track } = useMixpanel();

  useEffect(() => {
    if (loading) {
      showLoader("Iniciando sesión...");
    } else {
      Swal.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const login = useCallback(
    async (email?: string, clave?: string) => {
      setLoading(true);
      try {
        if (!email || !clave) {
          const token = localStorage.getItem("token");
          if (token && token !== "undefined") {
            if (!isExpired(token) || token === "undefined") {
              if (!userState?.user) {
                // const user = await recoverDataUser();
                // setUser(user);
                setToken(token);
              }
              if (location.pathname === "/inicio") {
                return navigate("/");
              }
              return;
            }
            track("Token expirado");
            showPopup({
              message:
                "Su sesión ha expirado, por favor inicie sesión nuevamente",
              title: "Sesión expirada",
              icon: "error",
            });
          }
          return navigate("/inicio");
          // return
        }

        // Primer paso: prelogin
        const preLoginRes = await preLoginReq(email, clave);

        if (preLoginRes.status === "200" && preLoginRes.data?.data?.[0]) {
          // Obtener datos del primer local disponible
          const company = preLoginRes.data.data[0];
          const local = company.locals[0];

          // Segundo paso: login final
          const loginRes = await loginFinalReq(
            email,
            clave,
            company.companyId,
            local.localId,
          );

          if (loginRes.status === "200") {
            setError(undefined);

            if (loginRes.data?.token) {
              localStorage.setItem("token", loginRes.data.token);
              const decode: any = decodeToken(loginRes.data.token);
              const employee = {
                idEmployee: decode.group?.employeeId,
                employeeName: decode.group?.employeeName,
              };
              localStorage.setItem("employee", JSON.stringify(employee));
              const { group: userData }: { group: IUserResponse } = decode;

              // Aplicar el adaptador antes de guardar en el contexto
              const adaptedUserData = adaptUserData(userData);
              setUser(adaptedUserData);
              setToken(loginRes.data.token);
              track("Iniciar sesion exitoso");
            }
            navigate("/comandas");
          } else {
            setError(loginRes.message);
            track("Iniciar sesion fallido", { data: loginRes.message });
            await showPopup({
              message: loginRes.message,
              icon: "error",
            });
          }
        } else {
          setError(preLoginRes.message);
          track("Iniciar sesion fallido", { data: preLoginRes.message });
          await showPopup({
            message: preLoginRes.message,
            icon: "error",
          });
        }
      } catch (error: any) {
        track("Iniciar sesion fallido", error.message);
        await showPopup({
          message:
            (error.message && error.message !== "Failed to fetch") ||
            "Parece que no tienes conexión a internet, por favor revisa tu conexión y recarga la página",
          icon: "error",
          confirmButtonText: "Recargar",
        });
        window.location.reload();
      } finally {
        setLoading(false);
        Swal.close();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate],
  );

  const logout = () => {
    track("Cerrar sesion");
    localStorage.removeItem("token");
    localStorage.removeItem("employee");
    localStorage.removeItem("preLoginToken");
    window.location.reload();
  };

  const recoverPass = async (email: string) => {
    setLoading(true);
    try {
      const res: IRequest = await recoverPassReq(email);
      setLoading(false);
      if (res.status === "200") {
        track("Recuperación de contraseña exitosa");
        setTimeout(() => {
          showPopup({
            message:
              "Hemos enviado un correo para la recuperación de la cuenta",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        }, 300);
      } else {
        track("Recuperación de contraseña fallida", { data: res.message });
        setTimeout(() => {
          showPopup({
            message: `${res.message}`,
            icon: "error",
          });
        }, 300);
      }
    } catch (error) {
      track("Recuperación de contraseña fallida", { data: error });
      setTimeout(() => {
        showPopup({
          message: `${"Ocurrio un error"}`,
          icon: "error",
        });
      }, 300);
      return;
    } finally {
      setLoading(false);
    }
  };

  return {
    user,
    error,
    loading,
    recoverPass,
    login,
    logout,
  };
};

// Interfaces para manejar la respuesta actual y el formato que necesitamos
interface IUserResponse {
  employeeId: number;
  localId: number;
  companyId: number;
  companyName: string;
  localName: string;
  employeeName: string;
}

// Función adaptadora
const adaptUserData = (userData: IUserResponse): IUser => {
  return {
    empleadoId: userData.employeeId,
    empresaId: userData.companyId,
    localId: userData.localId,
    nombre: userData.employeeName,
    nombreLocal: userData.localName,
    apellido: "",
    email: "",
    tipoUsuario: 3,
    admin: true,
    crearComanda: true,
    tipoPrivacidadDatosCliente: 1,
  };
};
