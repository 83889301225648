import { useEffect } from "react";
import { getServiceHistoryByDates } from "../../request/service-history";
import {
	BodyContainer,
	Main,
	StyledLabel,
	StyledText,
} from "../../components/styled/styled";
import "./service-history.css";
import CardHistorial from "./cards/card-historial/card-historial";
import { useState } from "react";
import Loader from "../../components/elements/loader/loader";
import { showPopup } from "../../utils/showAlert";
import { dark_violet, soft_gray } from "../../components/styled/constant";
import styled from "styled-components";
import ModalDatePicker from "./modal/modalDatePicker";
import calendar from "../../assets/icon-calendar.svg";
import interval from "../../assets/icon-time-interval.svg";
import ModalDateRangePicker from "./modal/modalDateRangePicker";
import { formatToSoles } from "utils/FormatsHandler";

export default function ServiceHistory() {
	const [historial, setHistorial] = useState<any>();
	const today = new Date();
	const [filteredComandasNoPagadas, setFilteredComandasNoPagadas] = useState<any>();
	const [filteredComandasPagadas, setFilteredComandasPagadas] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showModalRange, setShowModalRange] = useState(false);
	const [startDate, setStartDate] = useState(
		today.getFullYear() +
			"-" +
			("0" + (today.getMonth() + 1)).slice(-2) +
			"-" +
			("0" + today.getDate()).slice(-2)
	);

	useEffect(() => {
		loadData(false);
	}, [startDate]); //eslint-disable-line

	async function loadData(refresh: boolean) {
		if (refresh === false) {
			setIsLoading(true);
		}
		try {
			let data = await getServiceHistoryByDates(startDate, startDate);
			setHistorial(data);
			setFilteredComandasPagadas(data.comandaPagada);
			setFilteredComandasNoPagadas(data.comandaPendientePago);
		} catch (error) {
			showPopup({
				message: "No se pudo cargar el historial, por favor recarga la página",
				icon: "error",
			});
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<ModalDatePicker
				show={showModal}
				setShowModal={setShowModal}
				fadeOut={false}
				setStartDate={setStartDate}
			/>
			<ModalDateRangePicker
				show={showModalRange}
				setShowModal={setShowModalRange}
				fadeOut={false}
			/>
			<BodyContainer>
				<Main>
					{isLoading ? (
						<Loader />
					) : (
						<>
							<span>Selecciona la fecha que quieres consultar</span>
							<br />
							<div
								style={{
									display: "flex",
									alignItems: "center",
									margin: "10px 0 0 0",
								}}
							>
								<img
									src={calendar}
									alt="fecha"
									style={{ height: "14px", marginRight: "10px" }}
								/>
								<StyledLabel
									color="purple_1"
									style={{
										margin: "0 30px 0 0",
										width: "200px",
										position: "relative",
									}}
								>
									<Input
										type="date"
										style={{
											color: "black",
										}}
										value={startDate}
										onChange={(e) => setStartDate(e.target.value)}
									/>
									<span style={{ color: "#45348E" }}>
										{new Date(startDate + "T00:00:00-05:00").toLocaleDateString("es-ES", {
											weekday: "short",
											month: "short",
											day: "numeric",
											year: "numeric",
										})}
									</span>
								</StyledLabel>
								<img
									src={interval}
									alt="intervalo"
									style={{ height: "20px" }}
									onClick={() => setShowModalRange(true)}
								/>
							</div>
							<div className="containerHistorial">
								<div className="containerCantidades">
									<div style={{ margin: "0px 10px 0px 0px" }} className="cardEstadistica">
										<p className="textoEstadistica">{historial?.cantidadServicios}</p>
										<p className="textoDetalle">Total de servicios</p>
									</div>
									<div style={{ margin: "0px 0px 0px 0px" }} className="cardEstadistica">
										<p className="textoEstadistica">{historial?.cantidadProductos}</p>
										<p className="textoDetalle">Total de productos</p>
									</div>
								</div>
								<div className="containerTotales">
									<div style={{ margin: "0px 10px 0px 0px" }} className="cardEstadistica">
										<p className="textoEstadistica">
											{formatToSoles(historial?.totalVentasServicios)}
										</p>
										<p className="textoDetalle">Total por servicios</p>
									</div>
									<div style={{ margin: "0px 0px 0px 0px" }} className="cardEstadistica">
										<p className="textoEstadistica">
											{formatToSoles(historial?.totalVentasProductos)}
										</p>
										<p className="textoDetalle">Total por productos</p>
									</div>
								</div>
								<p style={{ color: "#727070" }}>
									*Los totales se calculan incluyendo ventas cobradas sin pasar por
									comandas.
								</p>
							</div>
							<StyledLabel>Comandas pendientes de pago</StyledLabel>
							<StyledText>
								Aquí se muestran las comandas que aún no han sido cobradas
							</StyledText>
							{filteredComandasNoPagadas?.length ? (
								filteredComandasNoPagadas?.map((item: any, index: number) => {
									return <CardHistorial key={index} myKey={index} data={item} />;
								})
							) : (
								<GrayDiv>
									<StyledText>No hay comandas registradas</StyledText>
								</GrayDiv>
							)}

							<StyledLabel>Comandas pagadas</StyledLabel>
							<StyledText>Aquí aparecerán las comandas que han sido pagadas</StyledText>
							{filteredComandasPagadas?.length ? (
								filteredComandasPagadas?.map((item: any, index: number) => {
									return (
										<CardHistorial
											key={index}
											myKey={index}
											data={item}
											color={dark_violet + "59"}
											noEditable={true}
										/>
									);
								})
							) : (
								<GrayDiv>
									<StyledText>No hay comandas registradas</StyledText>
								</GrayDiv>
							)}
						</>
					)}
					<div style={{ height: "1rem" }}></div>
				</Main>
			</BodyContainer>
		</>
	);
}

const GrayDiv = styled.div`
	background-color: ${soft_gray};
	width: 100%;
	height: 10rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 10px;
	margin: 1rem 0;
`;

const Input = styled.input`
	position: absolute;
	margin-left: -10px;
	margin-top: -10px;
	opacity: 0;
	&::-webkit-calendar-picker-indicator {
		padding-left: 200px;
		margin-left: -130px;
	}
`;
