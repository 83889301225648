/* eslint-disable array-callback-return */
import { useCallback, useEffect, useState, useContext } from "react";
// import { AiFillEdit } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { IComandaDetailRequest } from "../../../interfaces/comanda/comanda";
import {
  getComandaDetail,
  getComandaFinishedDetail,
} from "../../../request/comanda";
import {
  black_500,
  dark_purple,
  magenta_1,
  purple_1,
  soft_gray,
  violet_1,
} from "../../../components/styled/constant";
import {
  BodyContainer,
  BorderDiv,
  StyledLabel,
} from "../../../components/styled/styled";
import { IServices, IClientData } from "../../../interfaces/comanda/comanda";
import { showPopup } from "../../../utils/showAlert";
import { showLoader } from "../../../utils/loader";
import { userContext } from "../../../context/UserContext/userContext";
import useMixpanel from "hooks/useMixpanel/useMixpanel";
import { formatToSoles } from "utils/FormatsHandler";
import pencil from "../../../assets/visibility/pencil.svg";
import { capitalizeWords } from "utils/formatString";

export default function Detail() {
  const { track } = useMixpanel();
  const params = useParams();
  const navigate = useNavigate();
  const [services, setServices] = useState<{
    inCourse: IServices[];
    inComing: IServices[];
    finished: IServices[];
    products: IServices[];
  }>({ inCourse: [], inComing: [], finished: [], products: [] });
  const {
    userState: { user },
  } = useContext(userContext);

  const [isLoading, setIsLoading] = useState(true);
  const [clientData, setClientData] = useState<IClientData>({
    id: 0,
    docType: "",
    docNumber: "",
    name: "",
    lastName: "",
    district: "",
    telefono: "",
  });
  const [totalPrice, setTotoalPrice] = useState(0);
  const { id, editable } = params;

  const initialCharge = useCallback(
    async (id, editable) => {
      try {
        const response: IComandaDetailRequest =
          editable === "0"
            ? await getComandaFinishedDetail(id)
            : await getComandaDetail(id);

        // Filtrado de servicios en curso
        const handlerInCourse = response.data
          .filter(
            (item) =>
              item.servicioId !== 0 &&
              item.productoId === 0 &&
              item.nombreEstado === "SERVICIO INICIADO",
          )
          .map(
            (service): IServices => ({
              id: service.id,
              state: service.nombreEstado,
              price: service.precio,
              attended: service.empleadoAtencion,
              name: service.servicio,
              quantity: service.cantidad,
            }),
          );

        // Filtrado de servicios por atender
        const handlerInComing = response.data
          .filter(
            (item) =>
              item.servicioId !== 0 &&
              item.productoId === 0 &&
              item.nombreEstado === "EN ESPERA",
          )
          .map(
            (service): IServices => ({
              id: service.id,
              state: service.nombreEstado,
              price: service.precio,
              attended: service.empleadoAtencion,
              name: service.servicio,
              quantity: service.cantidad,
            }),
          );

        // Filtrado de servicios finalizados
        const handlerFinished = response.data
          .filter(
            (item) =>
              item.servicioId !== 0 &&
              item.productoId === 0 &&
              (item.nombreEstado === "FINALIZADA" ||
                item.nombreEstado === "FINALIZADO"), // Agregamos ambas posibilidades
          )
          .map(
            (service): IServices => ({
              id: service.id,
              state: service.nombreEstado,
              price: service.precio,
              attended: service.empleadoAtencion,
              name: service.servicio,
              quantity: service.cantidad,
            }),
          );

        // Filtrado de productos
        const handlerProducts = response.data
          .filter((item) => item.productoId !== 0 && item.servicioId === 0)
          .map(
            (service): IServices => ({
              id: service.id,
              state: service.nombreEstado,
              price: service.precio,
              attended: service.empleadoAtencion,
              name: service.servicio,
              quantity: service.cantidad,
            }),
          );

        setServices({
          inCourse: handlerInCourse,
          inComing: handlerInComing,
          finished: handlerFinished,
          products: handlerProducts,
        });

        setTotoalPrice(
          response.data.reduce(
            (acc, curr) => acc + curr.precio * curr.cantidad,
            0,
          ),
        );
        setClientData({
          id: response.data[0].clienteId,
          docType: response.data[0].tipoDocumento?.toString() || "",
          docNumber: response.data[0].nroDocumento || "",
          name: response.data[0].clienteNombre,
          lastName: response.data[0].clienteApellido,
          district: response.data[0].distritoResidencia?.toString() || "",
          telefono: response.data[0].clienteTelefono,
        });
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        console.error(error);
        setTimeout(() => {
          showPopup({
            message: "Volver a Comandas",
            icon: "error",
          }).then(() => {
            navigate("/comandas");
          });
        }, 200);
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (id) {
      initialCharge(Number(id), editable);
    }
  }, [id, editable, initialCharge]);

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      Swal.close();
    }
    return () => {
      Swal.close();
    };
  }, [isLoading]);

  return (
    <>
      <BodyContainer>
        <Main>
          <MainContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <StyledEditLabel
                onClick={() => {
                  navigate("/comandas");
                }}
              >
                {/* <IoArrowBack size={20} /> */}
                Volver
              </StyledEditLabel>
              {editable === "0" ? (
                ""
              ) : (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <StyledEditLabel
                    onClick={() => {
                      track("Editar Comanda");
                      navigate("editar");
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <img src={pencil} alt="pencil" />
                    <span>Editar</span>
                    {/* <StyledEditIcon size={22} /> */}
                  </StyledEditLabel>
                </span>
              )}
            </div>

            <StyledClientTitle>Datos del cliente</StyledClientTitle>
            <BorderDiv>
              <StyledClientDataMain>
                {user?.tipoPrivacidadDatosCliente !== 3 ? (
                  <>
                    <ClientDataTitle>Tipo de documento</ClientDataTitle>
                    <ClientDataContent>{clientData.docType}</ClientDataContent>
                    <ClientDataTitle>N° de documento</ClientDataTitle>
                    <ClientDataContent>
                      {clientData.docNumber}
                    </ClientDataContent>
                  </>
                ) : null}

                <ClientDataTitle>
                  {clientData.docType === "RUC"
                    ? "Razón social"
                    : "Nombres y apellidos"}
                </ClientDataTitle>
                <ClientDataContent>
                  {clientData.name + " " + clientData.lastName}
                </ClientDataContent>

                <ClientDataTitle>N° de teléfono</ClientDataTitle>
                <ClientDataContent>
                  {clientData.telefono || "No registrado"}
                </ClientDataContent>
              </StyledClientDataMain>
            </BorderDiv>
            {services.inCourse.length > 0 && (
              <>
                <StateTitle>Servicios en curso</StateTitle>
                {services.inCourse.map((service: IServices) => {
                  return (
                    <InCourseDiv>
                      <TitleServiceCard>
                        <label>{service.name}</label>
                        <label>
                          {formatToSoles(service.price * service.quantity)}
                        </label>
                      </TitleServiceCard>
                      <AtempededServiceCard color="white">
                        Atendido por{" "}
                        <i>{capitalizeWords(service.attended || "")}</i>
                      </AtempededServiceCard>
                    </InCourseDiv>
                  );
                })}
              </>
            )}
            {services.inComing.length > 0 && (
              <>
                <StateTitle>Por atender</StateTitle>
                {services.inComing.map((service: IServices) => {
                  return (
                    <InComingDiv>
                      <TitleServiceCard>
                        <label>{service.name}</label>
                        <label>
                          {formatToSoles(service.price * service.quantity)}
                        </label>
                      </TitleServiceCard>
                      <AtempededServiceCard>
                        Será atendido por{" "}
                        <i>{capitalizeWords(service.attended || "")}</i>
                      </AtempededServiceCard>
                    </InComingDiv>
                  );
                })}
              </>
            )}
            {services.products.length > 0 && (
              <>
                <StateTitle>Productos añadidos</StateTitle>
                {services.products.map((service: IServices) => {
                  return (
                    <StyledProductsDiv>
                      <TitleServiceCard>
                        <ServiceName>{service.name}</ServiceName>
                        <TotalPrice>
                          {formatToSoles(service.price * service.quantity)}
                        </TotalPrice>
                      </TitleServiceCard>
                      <DetailsContainer>
                        <AtempededServiceCard color={"rgba(66, 66, 66, 0.6)"}>
                          Vendido por{" "}
                          <i>{capitalizeWords(service.attended || "")}</i>
                        </AtempededServiceCard>
                        <PriceDetails>
                          {formatToSoles(service.price)} x {service.quantity}
                        </PriceDetails>
                      </DetailsContainer>
                    </StyledProductsDiv>
                  );
                })}
              </>
            )}
            {services.finished.length > 0 && (
              <>
                <StateTitle>Servicios terminados</StateTitle>
                {services.finished.map((service: IServices) => {
                  return (
                    <FinishedDiv>
                      <TitleServiceCard>
                        <label>{service.name}</label>
                        <label>
                          {formatToSoles(service.price * service.quantity)}
                        </label>
                      </TitleServiceCard>
                      <AtempededServiceCard>
                        Atendido por{" "}
                        <i>{capitalizeWords(service.attended || "")}</i>
                      </AtempededServiceCard>
                    </FinishedDiv>
                  );
                })}
              </>
            )}
            {services.inCourse.length === 0 &&
              services.inComing.length === 0 &&
              services.finished.length === 0 &&
              services.products.length === 0 && (
                <StateTitle>No hay servicios</StateTitle>
              )}
          </MainContainer>
        </Main>
      </BodyContainer>
      <FooterContainer>
        <StyledFooter>
          <label>Total a cobrar</label>{" "}
          <label>{formatToSoles(totalPrice)}</label>
        </StyledFooter>
      </FooterContainer>
    </>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  color: ${magenta_1};
`;

const MainContainer = styled(Main)`
  justify-content: center;
  width: 100%;
  min-height: fit-content;
`;

// const StyledEditIcon = styled(AiFillEdit)`
// `;

const StyledEditLabel = styled(StyledLabel)`
  align-items: center;
  text-align: end;
  color: ${dark_purple};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  width: auto;
`;

const StyledClientTitle = styled.h2`
  color: rgb(69, 52, 142);
  font-weight: 800;
  margin: 1rem 0px 0.5rem 0px;
  display: flex;
  text-align: start;
  align-items: start;
  justify-content: start;
  width: 100%;
`;

const StyledClientDataMain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  color: ${violet_1};
  flex-wrap: wrap;
`;

const ClientDataTitle = styled.label`
  color: #000;
  font-weight: 600;
  margin: 5px 0;
  width: 50%;
`;

const ClientDataContent = styled.label`
  color: #000;
  font-weight: 400;
  margin: 5px 0;
  width: 50%;
  word-break: break-all;
  hyphens: auto;
`;

const StateTitle = styled.label`
  color: ${purple_1};
  font-weight: 800;
  margin: 5px 0;
`;

const InCourseDiv = styled(BorderDiv)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #937cf4;
  border: none;
  color: white;
  width: -webkit-fill-available;
  min-height: 110px;
  justify-content: space-around;
`;
const InComingDiv = styled(BorderDiv)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${purple_1};
  width: -webkit-fill-available;
  justify-content: space-around;
  min-height: 110px;
  label {
    color: ${purple_1};
  }
`;
const FinishedDiv = styled(BorderDiv)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${violet_1}99;
  border-color: ${violet_1}99;
  width: -webkit-fill-available;
  justify-content: space-around;
  min-height: 110px;
  label {
    color: ${violet_1}99;
  }
`;

const TitleServiceCard = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: -webkit-fill-available;
  label {
    font-weight: 700;
    width: auto;
    word-break: break-all;
  }
  p {
    font-weight: 700;
    margin: 0;
    margin-bottom: auto;
  }
`;

const AtempededServiceCard = styled.label.attrs(
  (props: { color?: string }) => ({ color: props.color || "initial" }),
)`
  font-weight: 500;
  margin: 5px 0;
  color: ${(props) => props.color};
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: -webkit-fill-available;
  height: auto;
  min-height: 64px;
  background-color: ${purple_1};
  color: white;
  bottom: 0;
  left: 0;
  label {
    text-align: center;
    font-weight: 700;
  }
`;

const FooterContainer = styled.div`
  position: relative;
  bottom: 0;
`;

const StyledProductsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: -webkit-fill-available;
  background-color: ${soft_gray};
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  min-height: 110px;
  justify-content: space-around;
`;

const ServiceName = styled.span`
  color: ${black_500};
  font-weight: 700;
`;

const TotalPrice = styled.span`
  color: ${black_500};
  font-weight: 700;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const PriceDetails = styled.span`
  color: ${black_500}99;
  white-space: nowrap;
`;
