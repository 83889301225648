/**
 * Convierte la primera letra de cada palabra en mayúscula y el resto en minúscula
 * @param text - Texto a formatear
 * @returns String formateado o cadena vacía si hay error
 */
export const capitalizeWords = (text: string): string => {
  try {
    if (!text || typeof text !== "string") {
      return "";
    }

    return text
      .toLowerCase()
      .split(" ")
      .map((word) => {
        if (word.length === 0) return word;
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  } catch (error) {
    console.error("Error al capitalizar texto:", error);
    return "";
  }
};
