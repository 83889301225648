import "./card-comanda.css";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useState } from "react";
import { IComandaList } from "../../../interfaces/comanda/comanda";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { StyledColorContainer } from "../../../components/styled/styled";
import { capitalizeWords } from "utils/formatString";

export default function CardComanda(props: {
  data: IComandaList;
  key: string;
}) {
  let { data } = props;
  const navigation = useNavigate();
  let services = data.servicios;
  let restServices = services.slice(1);
  let [seeMore, setSeeMore] = useState(false);

  function handleClickSeeMore() {
    setSeeMore(!seeMore);
  }

  return (
    <StyledColorContainer>
      <div className="card" style={{ width: "-webkit-fill-available" }}>
        <p className="CardTitle">{`${data.clienteNombre} ${data.clienteApellido}`}</p>
        <div className="ContentItems">
          {CardItems(services[0].servicio, 0)}
          <div
            className={
              seeMore ? "ContentItems-more" : "ContentItems-more hidden"
            }
          >
            {restServices.map((item, index: number) => {
              return CardItems(item.servicio, index + 1);
            })}
          </div>
          {services.length > 1 && (
            <SeeMoreButton onClick={handleClickSeeMore}>
              <p className="CardContent">
                {seeMore ? "Ver menos" : "Ver mas"}{" "}
              </p>
              <MdOutlineKeyboardArrowDown
                className={seeMore ? "rotate" : "norotate"}
              />
            </SeeMoreButton>
          )}
        </div>
        <p className="createdBy">
          Creado por{" "}
          <i>{capitalizeWords(data.empleadoCreacion || "No encontrado")}</i>
        </p>
      </div>
      <ArrowRight
        onClick={() => navigation(`/comandas/detalle/${data.id}/1`)}
      />
    </StyledColorContainer>
  );
}

function CardItems(item: string, index: number) {
  return (
    <p key={index + "PoS"} className="CardContent">
      {item}
    </p>
  );
}

const ArrowRight = styled(MdOutlineKeyboardArrowRight)`
  width: 3rem;
  height: auto;
  color: #45348e;
  border: 1px solid #45348e;
  border-radius: 50%;
  aspect-ratio: 1;
  cursor: pointer;
`;

const SeeMoreButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: #45348e;
  .rotate {
    transform: rotate(180deg);
    transition: all 0.5s ease;
  }
  .norotate {
    transition: all 0.5s ease;
  }
`;
