import styled, { keyframes } from "styled-components";
import { violet_1 } from "../../../components/styled/constant";
import { StyledLabel } from "../../../components/styled/styled";

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

export const PopOverContent = styled.div.attrs(
  ({ show, display }: { show: boolean; display: boolean }) => ({
    show,
    display,
  }),
)`
position: absolute;
    background-color: #fff;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 0.5rem;
    right: 0;
    bottom: 130%;
    border: 1px solid  #dadada;
    animation: ${(props: { show: boolean }) => (props.show ? fadeIn : fadeOut)} 0.3s ease-in-out;
    animation-fill-mode: forwards;
    display: ${(props: { display: boolean }) => (props.display ? "block" : "none")};
    &::before {
        content: '';
        position: absolute;
        top: 100%;
        right: 0;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid ${violet_1};
`;

const collapseAnimation = keyframes`
    0% {
        height: 0;
    }
    99% {
        height: 100%;
    }
    100% {
        height: auto;
        overflow: visible;
    }
`;

const expandAnimation = keyframes`

    0% {
        height: 100%;
    }

    99% {
        height: 0%;
    }
    100% {
        height: 0px;
        overflow: hidden;
    }
`;

export const CollapseDataClient = styled.div.attrs(
  ({ show, display }: { show: boolean; display: string }) => ({
    show,
    display,
  }),
)`
  animation: ${(props) => (props.show ? collapseAnimation : expandAnimation)}
    0.5s ease-in-out;
  animation-fill-mode: forwards;
  overflow: visible;
  display: ${(props) => props.display};
`;

const rotateArrow = keyframes`
    from {
        transform: rotate(0deg);
        left: -15px;
    }
    to {
        transform: rotate(-90deg);
        left: -15px;
    }
`;

const rotateArrowBack = keyframes`
    from {
        transform: rotate(-90deg);
        left: -15px;
    }
    to {
        transform: rotate(0deg);
        left: -15px;
    }
`;

export const CollapseDataClientHeader = styled(StyledLabel).attrs(
  ({ turn }: { turn: boolean }) => ({ turn }),
)`
  margin-left: 15%;
  cursor: pointer;
  margin: 0.5rem 3rem;
  text-decoration-line: underline;
  text-decoration-thickness: 0.2rem;
  text-underline-offset: 0.2rem;
  min-height: 30px;
  position: relative;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateX(-100%);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid ${violet_1};
    animation: ${(props) => (props.turn ? rotateArrow : rotateArrowBack)} 1s
      ease-in-out;
    animation-fill-mode: forwards;
  }
`;
