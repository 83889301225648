import styled from "styled-components";
import {
  black_500,
  gray_1,
  gray_2,
  gray_text,
  purple_1,
  violet_1,
  white,
} from "./constant";

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding-top: 2px;
`;

export const Main = styled("div")`
  background: white;
  height: -webkit-fill-available;
  width: 80%;
  margin: 0 auto;
  overflow-y: visible;
`;

export const DropDownContainer = styled("div")``;

export const DropDownHeader = styled("div").attrs(
  (props: { dropDown: boolean }) => ({ dropDown: props.dropDown }),
)`
  padding: 0.4em 2em 0.4em 1em;
  font-weight: 500;
  color: ${black_500};
  background: #f2efff;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #dadada;
  height: 40px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  position: relative;
  align-items: center;
  justify-content: start;
  display: flex;
  border-radius: 4px;
  ${(props: { dropDown: boolean }) =>
    props.dropDown &&
    `
        &:after {
          content: "";
          position: absolute;
          right: 1rem;
          top: 50%;
          height: 0;
          border-left: 0.55rem solid transparent;
          border-right: 0.55rem solid transparent;
          border-top: 0.55rem solid #44308C;
          transform: translateY(-50%);
        }
    padding-right: 3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    `}
`;

export const DropDownListContainer = styled("div")`
  z-index: 100;
  position: relative;
  height: 0;
`;

export const DropDownList = styled("ul")`
  overflow-y: scroll;
  padding: 6px;
  margin: 3px 0;
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 0.5rem;
  color: ${black_500};
  max-height: 40rem;
`;

export const ListItem = styled("li")`
  list-style: none;
  padding: 1rem;
  cursor: pointer;
  &:hover {
    background: ${violet_1 + "33"};
    color: ${black_500};
  }
  border-radius: 0.5em;
`;

export const StyledInput = styled("input")`
  padding: 0.4em 1em;
  border: 1px solid #dadada;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  font-family: "Mulish", sans-serif;

  &::placeholder {
    color: #9e9e9e;
    opacity: 1;
  }

  &:focus {
    outline: none;
    border-color: ${purple_1};
  }
`;

export const StyledLabel = styled("label").attrs(
  (props: { color?: string }) => ({ label: props.color }),
)`{
    text-align: start;
    display: flex;
    color: #45348E;
    font-family: "Mulish", sans-serif;
    font-size: var(--font-size-label);
    font-style: normal;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
}
`;

export const StyledButton = styled("button").attrs(
  (props: { notEnable: boolean }) => ({ notEnable: props.notEnable }),
)`
  margin: 0.5rem 0;
  border: 0;
  border-radius: 0.5rem;
  background: ${(props: { notEnable: boolean }) =>
    props.notEnable ? gray_2 : "#937CF4"};
  color: ${(props: { notEnable: boolean }) =>
    props.notEnable ? black_500 : white};
  width: 60%;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${(props: { notEnable: boolean }) =>
      props.notEnable ? gray_1 + "da" : violet_1 + "da"};
  }
`;

export const BorderDiv = styled.div`
  border: 1px solid #dadada;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem 0px 1rem 0rem;
  min-width: calc(100% - 2rem);
`;

export const StyledColorContainer = styled.div`
width: -webkit-fill-available;
background-color: #E2DBFF;
border-radius: 5px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 10px 20px;
margin: 10px 0;
min-height: 110px;
}`;

export const SeeMoreButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: white;
  .rotate {
    transform: rotate(180deg);
    transition: all 0.5s ease;
  }
  .norotate {
    transition: all 0.5s ease;
  }
`;
export const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const StyledCloseButton = styled.div.attrs(
  (props: { color: string }) => ({ color: props.color }),
)`
  width: 100%;
  display: flex;
  transform: rotate(45deg);
  color: ${(props) =>
    props?.color === "purple_1" ? purple_1 : props.color || black_500};
  cursor: pointer;
`;

export const StyledText = styled.p`
  margin: 1rem 0;
  font-weight: bold;
  color: ${gray_text};
  margin: 0;
`;

export const StyledparagraphLink = styled.p`
  color: ${purple_1};
  font-weight: 400, Regular;
  text-align: center;
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 3rem;
  text-decoration: underline;
  cursor: pointer;
`;
