import { useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import { userContext } from "../../../../context/UserContext/userContext";
import { TServiceCard } from "../../../../interfaces/productAndServices";
import { violet_1 } from "../../../../components/styled/constant";
import {
  DropDownContainer,
  DropDownHeader,
  StyledInput,
  StyledLabel,
} from "../../../../components/styled/styled";
import { GrMoney } from "react-icons/gr";
import { BsTrash } from "react-icons/bs";
import useMixpanel from "hooks/useMixpanel/useMixpanel";
import { formatToSoles } from "utils/FormatsHandler";
import { decodeTokenLS } from "utils/token";

type Props = {
  index: string;
  handlerClick: (index: string, service: TServiceCard, type: "p" | "s") => void;
  handlerChangeServices: (
    index: number,
    amount: number,
    employeerId: number,
    service?: TServiceCard,
  ) => void;
  service: any;
  handlerDeleteService: (index: string) => void;
  bgColor?: string;
};

export default function CardServices(props: Props): JSX.Element {
  const { track } = useMixpanel();
  const { handlerClick, index, service, handlerDeleteService, bgColor } = props;
  const { userState } = useContext(userContext);
  const [fadeOut, setFadeOut] = useState(false);
  const { user } = userState;
  const userLS = decodeTokenLS();
  type ThisService = {
    service: TServiceCard;
    quantity: number;
    employeeId: number;
    type: "p" | "s";
    nombreEstado: string;
  };

  const thisService: ThisService = service;

  const handlerDelete = (index: string): void => {
    track("EliminarS");
    setFadeOut(true);
    setTimeout(() => {
      handlerDeleteService(index);
    }, 500);
  };

  const onClickCard = () => {
    if (thisService.employeeId === user?.empleadoId)
      handlerClick(index, thisService.service, "s");
  };

  const employee = JSON.parse(localStorage.getItem("employee") || "{}");

  return (
    <StyledFormGroup fadeOut={fadeOut}>
      <StyledContainer style={{ alignItems: "flex-start" }} bgColor={bgColor}>
        <div style={{ width: "100%" }} onClick={onClickCard}>
          <StyledLabel style={{ marginBottom: "8px" }}>Servicio</StyledLabel>
          <DropDownContainer
            style={
              !thisService
                ? { pointerEvents: "auto", width: "100%", position: "relative" }
                : thisService.nombreEstado === "EN ESPERA"
                  ? {
                      pointerEvents: "auto",
                      width: "100%",
                      position: "relative",
                    }
                  : {
                      pointerEvents: "none",
                      width: "100%",
                      position: "relative",
                    }
            }
          >
            <DropDownHeader
              style={{
                marginBottom: "1rem",
              }}
            >
              {thisService?.service?.descripcion || "Seleccione un servicio"}
            </DropDownHeader>
          </DropDownContainer>
          <StyledLabel style={{ marginBottom: "8px" }}>Estilista</StyledLabel>
          <StyledInput
            disabled
            style={{
              width: "-webkit-fill-available",
              fontWeight: 500,
              textAlign: "center",
            }}
            value={
              thisService.employeeId === 0
                ? employee?.employeeName
                : thisService?.service?.empleadoAtencion ||
                  employee?.employeeName ||
                  userLS?.user?.nombre + " " + userLS?.user?.apellido
            }
          ></StyledInput>
        </div>
        <PriceContainer>
          <GrMoney />
          <label>
            {thisService
              ? formatToSoles(thisService?.service?.precio || 0)
              : formatToSoles(0)}
          </label>

          {thisService?.nombreEstado !== undefined && (
            <p style={{ ...bottomMessage, textDecoration: "none" }}>
              {thisService?.nombreEstado === "FINALIZADA"
                ? "FINALIZADO"
                : thisService?.nombreEstado}
            </p>
          )}

          {thisService?.employeeId === userLS?.user?.empleadoId &&
            thisService?.nombreEstado === "EN ESPERA" && (
              <p
                style={{
                  ...bottomMessage,
                  position: "relative",
                  pointerEvents: "visible",
                }}
                onClick={() => {
                  handlerDelete(index);
                }}
              >
                Eliminar
                <BsTrash />
              </p>
            )}
        </PriceContainer>
      </StyledContainer>
    </StyledFormGroup>
  );
}

const fadeInLeft = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
`;

const fadeOutRight = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`;
const StyledFormGroup = styled("div").attrs((props: { fadeOut: boolean }) => ({
  fadeOut: props.fadeOut,
}))`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: -webkit-fill-available;
  position: relative;
  transform: translateX(-100vw);
  animation: ${(props: { fadeOut: boolean }) => {
      return props.fadeOut ? fadeOutRight : fadeInLeft;
    }}
    0.5s ease-in-out;
  animation-fill-mode: forwards;
  z-index: 250;
`;

const StyledContainer = styled.div.attrs((props: { bgColor?: string }) => ({
  bgColor: props.bgColor,
}))`
  display: flex;
  background-color: ${(props) => props.bgColor || "none"};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5%;
  border-radius: 0.5rem;
  border: 1px solid ${violet_1};
  width: -webkit-fill-available;
`;

const PriceContainer = styled.div`
  color: ${violet_1};
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 0.5rem;
  width: -webkit-fill-available;
  font-weight: bold;
  margin-top: 2rem;
  & label {
    color: ${violet_1};
    margin-left: 1rem;
  }
  & svg {
  }
  & > svg {
    & path {
      stroke: ${violet_1};
    }
  }
`;

const bottomMessage = {
  display: "inherit",
  textDecorationLine: "underline",
  textUnderlineOffset: "2px",
  cursor: "pointer",
  alignItems: "center",
  fontWeight: 500,
  zIndex: 100,
};
