import { useCallback, useEffect, useState } from "react";
import { useLogin } from "../../hooks/login";
import { validateEmail } from "../../utils/validate";
import "./login.css";

import logo from "../../assets/brands/ZYMPLE-SVG.svg";
import {
  StyledLabel,
  StyledparagraphLink,
} from "../../components/styled/styled";
import styled from "styled-components";
import {
  purple_1,
  gray_1,
  principalDegree,
} from "../../components/styled/constant";
import { FaFileDownload } from "react-icons/fa";
import TextInput from "../../components/textInput/TextInput";
import PasswordInput from "../../components/passwordInput/PasswordInput";

function Login() {
  const { login } = useLogin();
  const [formErrors, setFormErrors] = useState({ email: "", password: "" });
  const [input, setInput] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [isReadyForInstall, setIsReadyForInstall] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState({} as any);
  const [passwordVisibility, setPasswordVisibility] = useState({
    isVisible: false,
    isToggleAvailable: true,
    setIsVisible: (value: boolean) => {
      setPasswordVisibility((prev) => ({ ...prev, isVisible: value }));
    },
  });

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsReadyForInstall(true);
    });
  }, []); // eslint-disable-line

  const handleInstall = useCallback(() => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        setDeferredPrompt(null);
      });
    }
  }, [deferredPrompt]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let handleErrors = formErrors;
    let { email, password } = input;
    handleErrors.email = validateEmail(email);
    password
      ? (handleErrors.password = "")
      : (handleErrors.password = "Ingrese una contraseña");
    setFormErrors({ ...handleErrors });
    if (handleErrors.email === "" && handleErrors.password === "") {
      login(email, password);
    }
  }

  return (
    <div className="App form-login login-page">
      {
        <FaFileDownload
          style={{
            height: "1rem",
            width: "1rem",
            position: "absolute",
            opacity: 0,
          }}
          onClick={handleInstall}
        />
      }
      <LogoContainer>
        <img
          src={logo}
          alt="logo"
          className="logo-login"
          style={{
            width: "7rem",
            height: "7rem",
          }}
        />
        <label
          style={{
            textAlign: "center",
            padding: "0 3rem",
            width: "100%",
            color: "#45348E",
          }}
        >
          Comandas
        </label>
      </LogoContainer>
      <StyledForm onSubmit={handleSubmit}>
        <FormBody>
          <div className="formgroup">
            <TextInput
              label="Correo electrónico"
              name="email"
              value={input.email}
              handleChange={(e: any) =>
                setInput({ ...input, email: e.target.value })
              }
              onFocus={() => setFormErrors({ ...formErrors, email: "" })}
              placeholder="Email"
              error={formErrors.email}
              touched={!!formErrors.email}
              required
              inputProps={{
                style: formErrors.email
                  ? {
                      color: "#E80B0B",
                      borderColor: "#E80B0B",
                    }
                  : {},
              }}
            />
          </div>
          <div className="formgroup">
            <PasswordInput
              label="Contraseña"
              touched={!!formErrors.password}
              error={formErrors.password}
              visibility={passwordVisibility}
              id="password"
              inputProps={{
                name: "password",
                value: input.password,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setInput({ ...input, password: e.target.value }),
                onFocus: () => setFormErrors({ ...formErrors, password: "" }),
                placeholder: "Contraseña",
                required: true,
                type: passwordVisibility.isVisible ? "text" : "password",
                style: formErrors.password
                  ? {
                      color: "#E80B0B",
                      borderColor: "#E80B0B",
                    }
                  : {},
              }}
            />
          </div>
          <button id="Login" type="submit" className="btn">
            Iniciar sesión
          </button>
          <StyledparagraphLink
            id="ToRecoverPass"
            as="a"
            href="https://app.zymple.io/recuperar"
            target="_blank"
            rel="noopener noreferrer"
          >
            Olvidé mi contraseña
          </StyledparagraphLink>
        </FormBody>
        {isReadyForInstall && (
          <DownloadAppContainer>
            <StyledLabel>Descarga la app </StyledLabel>
            <DownloadButton onClick={handleInstall}>Aquí</DownloadButton>
          </DownloadAppContainer>
        )}
      </StyledForm>
    </div>
  );
}

export default Login;

const StyledForm = styled.form`
  height: -webkit-fill-available;
  background-color: #fff;
  border-radius: 2rem 2rem 0 0;
  padding-top: 2rem;
  justify-content: space-between;
  background-color: #f2efff;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  width: auto;
`;

const DownloadAppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: -webkit-fill-available;
  height: auto;
  bottom: 0;
  background-color: ${gray_1};

  & > label {
    width: auto;
    color: ${purple_1};
  }
`;

const DownloadButton = styled.div`
  background: ${principalDegree};
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 999px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 0.5rem;
  cursor: pointer;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
