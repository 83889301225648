import React from "react";
import styled from "styled-components";

//@ts-ignore
import style from "./TextInput.module.css";
// import { iconPath } from "../../../../../models/localPath";

interface TextInputProps {
  label?: string;
  name?: string;
  value?: string;
  touched?: boolean;
  error?: any;
  handleChange?: any;
  handleBlur?: any;
  placeholder?: string;
  disabled?: boolean;
  keyboardType?: string;
  required?: boolean;
  maxLength?: number;
  prefix?: string;
  suffix?: string;
  width?: string;
  flex?: string;
  inputProps?: any;
  rightIcon?: string;
  leftIcon?: string;
  id?: string;
  onKeyDown?: any;
  inputMode?: string;
  minWidth?: string;
  autoFocus?: boolean;
  inputRef?: any;
  onClick?: any;
  onFocus?: any;
  isLoading?: boolean;
  loadingMessage?: string;
}

const LoaderContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #dadada;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const TextInput: React.FC<TextInputProps> = ({
  label,
  name,
  value = "",
  touched,
  error,
  handleChange = (e: any) => {},
  handleBlur = (e: any) => {},
  placeholder,
  disabled,
  keyboardType,
  required,
  maxLength,
  prefix,
  suffix,
  width = "",
  minWidth = "",
  flex = "none",
  inputProps = {},
  rightIcon,
  leftIcon,
  id = "",
  onKeyDown = () => {},
  inputMode,
  inputRef,
  onClick,
  onFocus,
  isLoading = false,
  loadingMessage = "",
}) => {
  return (
    <div
      className={style.inputContainer}
      style={{ width: width, flex: flex, minWidth: minWidth }}
    >
      {label && (
        <label className={style.inputLabel}>
          {label}
          {required && <p className={style.inputRequired}>*</p>}
        </label>
      )}
      <div className={style.inputContent}>
        {prefix && <span className={style.inputPrefix}>{prefix}</span>}
        <input
          onFocus={onFocus}
          onClick={onClick}
          ref={inputRef}
          name={name}
          value={value}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => handleBlur(e)}
          placeholder={placeholder}
          disabled={disabled}
          type={keyboardType}
          onKeyDown={onKeyDown}
          inputMode={inputMode}
          min={0}
          max={maxLength}
          required={required}
          id={id}
          className={`${style.inputText} ${
            prefix || leftIcon ? style.inputTextPrefix : ""
          } ${suffix || rightIcon ? style.inputTextSuffix : ""} ${
            disabled ? style.inputTextDisabled : ""
          } ${touched && error ? style.inputTextError : ""}`}
          {...inputProps}
        />
        {suffix && <span className={style.inputSuffix}>{suffix}</span>}
        {rightIcon && (
          <img className={style.rightIcon} src={rightIcon} alt="right icon" />
        )}
        {leftIcon && (
          <img className={style.leftIcon} src={leftIcon} alt="left icon" />
        )}
        {isLoading && (
          <>
            <LoaderContainer>
              <Spinner />
            </LoaderContainer>
            {/* {loadingMessage && <LoadingMessage>{loadingMessage}</LoadingMessage>} */}
          </>
        )}
      </div>
      {isLoading && loadingMessage && (
        <span className={style.inputErrorContainer}>
          <p className={style.loadingMessage}>{loadingMessage}</p>
        </span>
      )}
      {touched && error && (
        <span className={style.inputErrorContainer}>
          <p className={style.inputErrorMessage}>{error}</p>
        </span>
      )}
    </div>
  );
};

export default TextInput;
