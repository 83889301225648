import {
  IServiceQueue,
  IServiceQueueRequest,
} from "../interfaces/service-queue";
import fetchTimeout from "../utils/fetchTimeout";
import { decodeTokenLS } from "../utils/token";
import { setHeaders, URL_REQUEST } from "./constants";
import { IRequest } from "../interfaces/request";

/**
 * Obtiene la cola de servicios para un empleado
 * @returns Promise<IServiceQueue[]> Array de servicios en cola
 * @throws Error si el token es inválido o hay error en la petición
 */
export async function getServiceQueue(): Promise<IServiceQueue[]> {
  const decoded = decodeTokenLS();
  if (!decoded) {
    throw new Error("Token Inválido, por favor vuelva a iniciar sesión");
  }
  const employee = JSON.parse(localStorage.getItem("employee") || "{}");

  // Realizar petición GET al endpoint
  const res = await fetchTimeout(
    URL_REQUEST + "/comanda/getComandasItemsForEmployee",
    {
      headers: {
        ...setHeaders(),
        Authorization: decoded.token, // Simplificado la sintaxis
      },
      method: "GET",
    },
  );

  const response: IServiceQueueRequest = await res.json();

  if (response.status === "200") {
    // Transformar la respuesta al formato requerido
    return response.data.map((item: any) => ({
      id: item.ComandaDetailId,
      codigoComanda: `000${item.ComandaId}`,
      detalleId: item.ComandaDetailId,
      empleadoId: employee.employeeId,
      empleadoAtencion: employee.employeeName,
      empleadoCreacion: employee.employeeName,
      servicio: item.PriceServiceDescription,
      cantidad: item.Quantity,
      cliente: item.ClientName,
      // Separar nombre y apellido del cliente
      clienteNombre: item.ClientName,
      clienteApellido: item.ClientName,
      clienteId: item.ClientId,
      fechaCreacion: formatearFecha(item.startDate),
      precio: 0,
      servicioId: item.ServiceId,
      productoId: null,
      nombreEstado: item.Description.toUpperCase(),
      estadoId: item.Status,
      fechaInicio: formatearFecha(item.startDate),
      fechaFin: "",
      serviceDescription: item.ServiceDescription,
    }));
  }

  throw new Error(response.message);
}

/**
 * Función auxiliar para formatear fechas
 * @param fecha - Fecha en formato ISO string
 * @returns string - Fecha formateada en formato dd/MM/yyyy HH:mm:ss
 */
function formatearFecha(fecha: string | null): string {
  if (!fecha) {
    return new Date().toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }

  const fechaObj = new Date(fecha);
  // Ajustar la zona horaria restando 3 horas para UTC-3
  fechaObj.setHours(fechaObj.getHours() - 3);

  return fechaObj.toLocaleString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
}

/**
 * Actualiza el estado de un servicio en la cola
 * @param state - Nuevo estado del servicio (newStatus)
 * @param detailId - ID del detalle de la comanda (comandaItemId)
 * @returns Promise<IRequest>
 * @throws Error si hay problemas con el token, la conexión o la respuesta del servidor
 */
export async function updateServiceState(
  state: number,
  detailId: number,
): Promise<IRequest> {
  try {
    const decoded = decodeTokenLS();
    if (!decoded) {
      throw new Error("Token Inválido, por favor vuelva a iniciar sesión");
    }

    // Modificar el formato del body para que coincida exactamente con Postman
    const raw = JSON.stringify({
      comandaItemId: detailId,
      newStatus: state,
    });

    // Agregar Content-Type al header y asegurarse que el token se envía correctamente
    const res = await fetchTimeout(
      URL_REQUEST + "/comanda/updateComandaItemStatus",
      {
        method: "PUT",
        headers: {
          ...setHeaders(),
          Authorization: decoded.token,
          "Content-Type": "application/json", // Agregar explícitamente
        },
        body: raw,
      },
    );

    // Intentar leer el cuerpo de la respuesta como texto primero
    const textResponse = await res.text();

    // Convertir a JSON solo si es válido
    const response = textResponse ? JSON.parse(textResponse) : {};

    if (response.status === "200") {
      return response;
    }
    throw new Error(response.message || "Error en la respuesta del servidor");
  } catch (error) {
    console.error("Error completo:", error);
    if (error instanceof Error) {
      throw new Error(
        `Error al actualizar el estado del servicio: ${error.message}`,
      );
    }
    throw new Error("Error inesperado al actualizar el estado del servicio");
  }
}
