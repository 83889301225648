/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { BsFillStopCircleFill } from "react-icons/bs";
import { IoRefreshCircle } from "react-icons/io5";
import { FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";
import Swal from "sweetalert2";
import { IServiceQueue } from "../../interfaces/service-queue";
import { getServiceQueue, updateServiceState } from "../../request/service-queue";
import { showPopup } from "../../utils/showAlert";
import Peace from "../comandas/groups/peace";
import Loader from "../../components/elements/loader/loader";
import { purple_1 } from "../../components/styled/constant";
import {
	BodyContainer,
	Main,
	StyledColorContainer,
} from "../../components/styled/styled";
import useMixpanel from "hooks/useMixpanel/useMixpanel";
import { capitalizeWords } from "utils/formatString";

export default function ServiceQueue() {
	const { track } = useMixpanel();
	const [timer, setTimer] = useState<{
		secons: number;
		minutes: number;
		hours: number;
	}>({
		secons: 0,
		minutes: 0,
		hours: 0,
	});
	const [queue, setQueue] = useState<{
		inProgress: IServiceQueue[];
		pending: IServiceQueue[];
	}>({ inProgress: [], pending: [] });

	const [loading, setLoading] = useState(false);

	const getTime = (service: IServiceQueue): string => {
		const localDate = new Date();
		const dateNow = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
		dateNow.setHours(dateNow.getHours() - 5);

		const [handlerDate, hours] = service.fechaInicio.split(" ");
		const [day, month, year] = handlerDate.split("/");
		const [hour, minute, second] = hours.split(":");
		const date = new Date(
			parseInt(year),
			parseInt(month) - 1,
			parseInt(day),
			parseInt(hour),
			parseInt(minute),
			parseInt(second)
		);

		const diff = dateNow.getTime() - date.getTime();

		const time = {
			hours: Math.floor(diff / 1000 / 60 / 60),
			minutes: Math.floor((diff / 1000 / 60) % 60),
			secons: Math.floor((diff / 1000) % 60),
		};
		const seconsFormat = time.secons < 10 ? "0" + time.secons : time.secons;
		const minutesFormat = time.minutes < 10 ? "0" + time.minutes : time.minutes;
		const hourFormat = time.hours < 10 ? "0" + time.hours : time.hours;

		return hourFormat + " : " + minutesFormat + " : " + seconsFormat;
	};
	const initQueue = useCallback(async (refresh: boolean) => {
		if (refresh === false) {
			setLoading(true);
		}
		try {
			const res: IServiceQueue[] = await getServiceQueue();
			const handlerInProgress = res
				.filter((item) => item.nombreEstado === "SERVICIO INICIADO")
				.map((item) => ({ ...item }));
			const handlerPending = res.filter((item) => item.nombreEstado === "EN ESPERA");

			setQueue({ inProgress: handlerInProgress, pending: handlerPending });
		} catch (err: any) {
			console.error(err);
			if (refresh === false) {
				showPopup({
					message: err || "Error al obtener la cola de servicio",
					icon: "error",
				});
			}
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		initQueue(false);
	}, [initQueue]);

	const updateTimmer = useCallback(({ secons, minutes, hours }) => {
		if (secons === 59) {
			if (minutes === 59) {
				setTimer({
					secons: 0,
					minutes: 0,
					hours: hours + 1,
				});
			} else {
				setTimer({
					secons: 0,
					minutes: minutes + 1,
					hours: hours,
				});
			}
		} else {
			setTimer({
				secons: secons + 1,
				minutes: minutes,
				hours: hours,
			});
		}
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			updateTimmer({
				secons: timer.secons,
				minutes: timer.minutes,
				hours: timer.hours,
			});
		}, 1000);
		return () => clearInterval(interval);
	}, [timer, updateTimmer]);

	const changeStatus = async (status: number, id: number) => {
		track(status === 1 ? "ReiniciarS" : status === 2 ? "IniciarS" : "DetenerS");

		type MessageType = {
			[key: number]: {
				title: string;
				message: string;
				confirmText: string;
			};
		};

		const messages: MessageType = {
			1: {
				title: "¿Desea regresar el servicio?",
				message: "El servicio volverá a la cola de espera",
				confirmText: "Si, regresar el servicio",
			},
			2: {
				title: "¿Deseas iniciar el servicio?",
				message: "",
				confirmText: "Si, iniciar el servicio",
			},
			3: {
				title: "¿Estás seguro de finalizar este servicio?",
				message: "Esta acción no se puede deshacer",
				confirmText: "Si, finalizar el servicio",
			},
		};

		const currentMessage = messages[status];

		const res = await showPopup({
			title: currentMessage.title,
			message: currentMessage.message,
			confirmButtonText: currentMessage.confirmText,
			cancelButtonText: "No",
		});

		if (!res.isConfirmed) {
			track(
				status === 1
					? "cancelarReiniciarS"
					: status === 2
						? "cancelarIniciarS"
						: "cancelarDetenerS"
			);
			return;
		}

		track(status === 1 ? "siReiniciarS" : status === 2 ? "siIniciarS" : "siDetenerS");

		try {
			const res = await updateServiceState(status, id);
			if (res) {
				initQueue(false);
			}
		} catch (err: any) {
			console.error(err);
			Swal.fire({
				title: "Error",
				text: err,
				icon: "error",
			});
		}
	};

	const showServiceDescription = (description: string) => {
		Swal.fire({
			title: "Descripción del servicio",
			text: description || "Sin descripción",
			confirmButtonText: "Cerrar",
		});
	};

	return (
		<BodyContainer>
			<Main>
				{loading ? (
					<Loader />
				) : (
					<>
						{queue.inProgress.length ? (
							<label style={{ color: purple_1, textAlign: "center" }}>
								<strong style={{ fontWeight: 800 }}>Están atendiendo a</strong>
							</label>
						) : null}
						{queue.inProgress.length
							? queue.inProgress.map((item, index) => (
									<ColorContainer key={index}>
										<StyledTitleContainer>
											<div>
												<StyledTitle>{item.clienteNombre}</StyledTitle>
												<StyledSubtitle>{item.servicio}</StyledSubtitle>
											</div>
											<TimmerAndIconsContainer>
												<IconsContainer>
													<FaInfoCircle
														size={"2rem"}
														onClick={() =>
															showServiceDescription(item.serviceDescription)
														}
														style={{ marginRight: "0.55rem" }}
													/>
													<IoRefreshCircle
														size={"2.4rem"}
														style={{ transform: "matrix(-1, 0, 0, 1, 0, 0)" }}
														onClick={() => changeStatus(1, item.detalleId)}
													/>
													<BsFillStopCircleFill
														style={{
															marginTop: "0.15rem",
															marginLeft: "0.55rem",
														}}
														size={"2rem"}
														onClick={() => changeStatus(3, item.detalleId)}
													/>
												</IconsContainer>
												<StyledTimer>{getTime(item)}</StyledTimer>
											</TimmerAndIconsContainer>
										</StyledTitleContainer>
										<StyledFooterCard>
											<StyledFooter>
												<label>Atendido por&nbsp;</label>
												<i>{" " + capitalizeWords(item.empleadoAtencion || "")}</i>
											</StyledFooter>
										</StyledFooterCard>
									</ColorContainer>
								))
							: null}
						{queue.pending.length ? (
							<label style={{ color: purple_1, textAlign: "center" }}>
								<strong style={{ fontWeight: 800 }}>A continuación</strong>
							</label>
						) : null}
						{queue.pending.length
							? queue.pending.map((item, index) => (
									<PendingCardContainer key={index}>
										<StyledTitleContainer>
											<div>
												<StyledTitle color={purple_1}> {item.clienteNombre}</StyledTitle>
												<StyledSubtitle color={purple_1}>{item.servicio}</StyledSubtitle>
											</div>
											<PendingIconContainer>
												<FaInfoCircle
													size={"2rem"}
													onClick={() => showServiceDescription(item.serviceDescription)}
													style={{ marginRight: "0.55rem" }}
												/>
												<AiFillPlayCircle
													size={"2.2rem"}
													onClick={() => changeStatus(2, item.detalleId)}
												/>
											</PendingIconContainer>
										</StyledTitleContainer>
										<PendingFooterCard>
											<StyledFooter>
												<label>Será atendido por&nbsp;</label>
												<i>{" " + capitalizeWords(item.empleadoAtencion || "")}</i>
											</StyledFooter>
										</PendingFooterCard>
									</PendingCardContainer>
								))
							: ""}
						{queue.pending.length === 0 && queue.inProgress.length === 0 ? (
							<Peace
								icon="peace2"
								text="Parece que todo está calmado por aqui, no hay servicios en cola por ahora"
							/>
						) : null}
					</>
				)}
			</Main>
		</BodyContainer>
	);
}

const ColorContainer = styled(StyledColorContainer)`
	flex-direction: column;
	align-items: flex-start;
	background: #937cf4;
	margin-bottom: 2rem;
`;

const StyledTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`;
const StyledTitle = styled.h1`
	color: ${(props) => props.color || "white"};
	font-weight: 700;
`;
const StyledSubtitle = styled.h2`
	color: ${(props) => props.color || "white"};
	font-weight: 500;
`;

const StyledFooterCard = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 8px;
	flex-direction: column;
	width: 100%;
`;

const StyledFooter = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	label {
		color: white;
		font-weight: 500;
		width: auto;
	}
	i {
		color: white;
	}
`;
const TimmerAndIconsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
`;

const IconsContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: row;
	color: white;
	align-items: center;

	svg {
		cursor: pointer;
	}
`;
const StyledTimer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: white;
	font-weight: 700;
`;

const PendingCardContainer = styled(ColorContainer)`
	background: white;
	border: 1px solid #d4d4d4;
	margin-top: 0.5rem;
`;

const PendingFooterCard = styled(StyledFooterCard)`
	label {
		color: ${purple_1};
	}
	i {
		color: ${purple_1};
	}
`;

const PendingIconContainer = styled(IconsContainer)`
	color: #45348e;
	cursor: pointer;
`;
